@import '../../utils/variables.css';

.ml-block-form {
    display: flex;
    position: relative;
    margin-bottom: 10px;
}

.ml-field-email > .form-control {
    font-family: 'Lato-Black', sans-serif;
    font-size: 16px;
    color: var(--davys-grey);
    background-color: transparent;
    background-image: url('/src/assets/images/subscriber-form/subscriber-input-field-black.png');
    background-repeat: no-repeat;
    width: 184px;
    height: 30px;
    border: none;
    outline: none;
    padding-left: 10px;
}

.ml-field-email > .form-error {
    background-image: url('/src/assets/images/subscriber-form/subscriber-input-field-red.png');
}

.ml-field-email > input:focus {
    color: var(--color-milk-white);
}

.ml-field-email > input::placeholder {
    margin-left: 10px;
}

.ml-form-embedSubmit .primary {
    font-family: 'Poison Hope', sans-serif;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #0A0909;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAAAfCAYAAAB59OpuAAABVUlEQVR4Ae2azU3EMBCFHYc75pIr7oQSaIUWqABKoBToYOkge82FUEAS3iAbjaJdraV94vQ+aeXxZj123vonjqcLYJqmp23bktld181I7GMkZ18imQ8rX305f6H4Sa6e0Zct1wOu573jvu/vLV3X9dvSGONtzVe7lE27Os9yomyubbS2ufxf++q9Vf/ehr+PYRjeOhMTX74EcTUQ9Rl/UnwMgoJpGYOgIkGJYOpMEpSMBCWCRendBM1BUMCQz+qhZCQomYin/dadkGggtmzTRDvR77vF9WgOJWNDXj2USFyW5TMICpg+RxvyWpSIaA4lgh56J0HJSFAiWOC/JCgZCUpGgpKRoGQkKBkJSkaCkpGgRLBTOkpQEjjx/H3JdIOn+1co+2AnduGfKMFYFwO77Him5fXiLoBrLnYu+XF/b96vC3CbT7TTB7rNZ+o+4ncHCHoIgs8Pjr6bgKJ6OYEAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    width: 84px;
    height: 32px;
    border: none;
    position: absolute;
    left: 194px;
}

.ml-form-embedSubmit .primary:hover {
    background-image: url('/src/assets/images/subscriber-form/subscriber-submit-green.png');
}

.ml-form-error-icon {
    width: 20px;
}

.ml-form-message {
    color: var(--color-milk-white);
    font-family: 'Lato-Italic', sans-serif;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 0;
    text-align: left;
}

.ml-form-error {
    color: var(--venetian-red);
    margin-left: 8px;
}