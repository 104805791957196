@import '../../utils/variables.css';

.main-menu-toolbar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.main-menu-signs-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 10px;
  margin-top: 3px;
  width: 85px;
}

.main-menu-underline-title {
  width: 0;
  height: 0;
  transition: width 0.5s ease-in-out;
  color: transparent;
}

.main-menu-welcome-container:hover .main-menu-underline-title {
  width: 267px;
  height: 3px;
  margin-top: 8px;
}
