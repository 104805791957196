@import '../../utils/variables.css';

.hamburger-main-menu-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 300px;
    height: 100%;
}

.hamburger-main-menu-body-container {
    display: flex;
    flex-direction: column;
}

.hamburger-main-menu-content-container {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
}

.hamburger-main-menu-subscribe {
    margin-top: 17px;
}

.hamburger-menu-divider-container {
    width: 208px;
    height: 3px;
    margin-left: 45px;
}
