@font-face {
    font-family: 'Poison Hope';
    src: url('../assets/fonts/PoisonHope-Regular-registered-mark-added.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    src: url('../assets/fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Black';
    src: url('../assets/fonts/Lato-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Italic';
    src: url('../assets/fonts/Lato-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Italic-300';
    src: url('../assets/fonts/Lato-LightItalic.ttf') format('truetype');
}

:root {
    --color-charcoal: #0A0909;
    --color-milk-white: #EBEBEB;
    --venetian-red: #FF0000;
    --crystal-blue: #6DA4AE;
    --davys-grey: #5A5A5A;
}
