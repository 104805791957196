.eye-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.eye-ball {
    position: absolute;
    top: 43%;
    left: 43%;
    width: 30px;
    height: 30px;
}

.eye-outline {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
